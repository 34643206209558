<template>
  <div class="bg-page-dark user-venue-deal-page h-100 d-flex flex-column">
    <b-container
      class="bg-white shadow-lg border flex-fill py-5 align-items-center d-flex w-100"
    >
      <div v-if="loading" class="d-flex w-100 justify-content-center">
        <loader class=""></loader>
      </div>
      <b-alert show variant="success" v-else-if="confirmed" class="w-100">
        <p style="font-size: 16px">
          Merci pour votre confirmation de réservation pour le
          {{ reservation.at.localeFormat }} a {{ reservation.venue.name }}.
        </p>
        <p class="text-center mt-4">
          <b-button variant="success" :to="{ name: 'booking' }">
            VOIR/MODIFIER VOS RÉSERVATIONS
          </b-button>
        </p></b-alert
      >
      <b-alert show variant="danger" v-else-if="error" class="w-100">
        <p style="font-size: 16px">
          Une erreur est survenue lors de la confirmation.
        </p>
        <p class="text-center">
          <b-link :to="{ name: 'booking' }">Retour</b-link>
        </p>
      </b-alert>
    </b-container>
  </div>
</template>
<script>
import CONFIRM_ONLINE_BOOKING from "@/graphql/mutations/ConfirmOnlineBooking.gql"
import Loader from "@/components/Loader"
export default {
  components: {
    Loader
  },
  data: () => ({
    reservation: null,
    confirmed: false,
    error: null,
    loading: true
  }),
  apollo: {},
  mounted() {
    this.$apollo
      .mutate({
        mutation: CONFIRM_ONLINE_BOOKING,
        client: "auth",
        variables: {
          reservationId: parseInt(this.$route.params.id, 10) || 0
        }
      })
      .then(response => {
        this.reservation = response.data.confirmOnlineBooking
        this.confirmed = true
      })
      .catch(() => {
        this.error = true
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {}
}
</script>
